<template>
    <BT-Blades :anchorBlades="[{ bladeName: 'courier-pointers' }]">
        <template v-slot="bladesData">
            <Courier-Pointers-Blade :bladesData="bladesData" />
            <Courier-Pointer-Blade :bladesData="bladesData" />
        </template>
    </BT-Blades>
</template>

<script>
export default {
    name: 'Courier-Pointers-Hub',
    components: {
        CourierPointerBlade: () => import('~home/courier-pointers/Courier-Pointer-Blade.vue'),
        CourierPointersBlade: () => import('~home/courier-pointers/Courier-Pointers-Blade.vue')
    }
}
</script>